import "core-js/modules/es.array.push.js";
import TabBar from '../common/TabBar';
import { ref } from 'vue';
export default {
  components: {
    TabBar
  },
  data() {
    return {
      checkedGoods: ['1', '2', '3'],
      newsList: [],
      newsPropertyList: [],
      loading: false,
      finished: false,
      newsStatus: 1,
      orderBy: 0,
      showShare: ref(false),
      newsStatusOption: [{
        text: '全部',
        value: -1
      }, {
        text: '未过期',
        value: 1
      }, {
        text: '已过期',
        value: 2
      }],
      orderByOption: [{
        text: '默认排序',
        value: 0
      }, {
        text: '结束时间',
        value: 1
      }, {
        text: '开始时间',
        value: 2
      }],
      options: [[{
        name: '微信',
        icon: 'wechat'
      }, {
        name: '朋友圈',
        icon: 'wechat-moments'
      }, {
        name: '微博',
        icon: 'weibo'
      }, {
        name: 'QQ',
        icon: 'qq'
      }], [{
        name: '复制链接',
        icon: 'link'
      }, {
        name: '分享海报',
        icon: 'poster'
      }, {
        name: '二维码',
        icon: 'qrcode'
      }, {
        name: '小程序码',
        icon: 'weapp-qrcode'
      }]]
    };
  },
  created() {
    this.loadData();
    this.loading = false;
  },
  computed: {},
  methods: {
    // 加载数据
    async loadData() {
      // this.loading = true
      let paras = {
        params: {
          newsStatus: this.newsStatus,
          orderBy: this.orderBy
        }
      };
      console.log(paras);
      try {
        this.$axios.get('/news/list/', paras).then(response => {
          this.newsList = response.data;
          console.log(response.data);
        });
        // Notify({
        //     message: '数据加载成功',
        //     type: 'success'
        // });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.finished = true;
      }
    },
    changeNewsStatusOption(options) {
      this.loadData();
    },
    changeOrderByOption(options) {
      console.log(options);
      this.loadData();
    },
    autoColor(importance) {
      if (importance == 1) return '#C0C0C0';
      if (importance == 2) return '#70DB93';
      if (importance == 3) return '#D9D919';
      if (importance == 4) return '#FFA500';
      if (importance == 5) return '#FF0000';
    },
    selectShare(option) {
      showToast(option.name);
      showShare.value = false;
    },
    go(link) {
      this.$router.push(link);
    }
  }
};